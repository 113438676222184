import makeValidationSchema from './BolFormMakeValidationSchema'
import { components, useTranslation } from 'cng-web-lib'
import React, { useEffect } from 'react'
import Namespace from '../../../../../constants/locale/Namespace'
import NbciUsInvoiceKeys from '../../../../../constants/locale/key/NbciUsInvoice'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    field: { CngTextField, CngSelectField,CngCodeMasterAutocompleteField }
  },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  containerNo: '',
  freightCharges: '',
  placeOfLadding: '',
  deliveryInstrctions: '',
  codAmount: '',
  bolCurrency: '',
  declaredValueOfShipment: '',
  chargeTo: '',
  releaseValue: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, shouldHideMap }) {
  const { setValue, watch } = useFormContext()

  const currency = watch('bolCurrency')
  useEffect(() => {
    setValue('bolCurrency', currency, { shouldDirty: true })
  }, [currency])

  const { translate } = useTranslation(Namespace.NBCI_US_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()
  
  function makeTranslatedTextsObject() {
    const containerNo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.CONTAINER_NO
    )
    const freightCharges = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.FREIGHT_CHARGES
    )
    const placeOfLadding = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.PLACE_OF_LADDING
    )
    const deliveryInstrctions = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.DELIVERY_INSTRUCTIONS
    )
    const codAmount = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.COD_AMOUNT
    )
    const bolCurrency = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.CURRENCY
    )
    const declaredValueOfShipment = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.DECLARED_VALUE_OF_SHIPMENT
    )
    const chargeTo = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.CHARGE_TO
    )
    const releaseValue = translate(
      Namespace.NBCI_US_INVOICE,
      NbciUsInvoiceKeys.BolForm.RELEASE_VALUE
    )

    return {
      containerNo,
      freightCharges,
      placeOfLadding,
      deliveryInstrctions,
      codAmount,
      bolCurrency,
      declaredValueOfShipment,
      chargeTo,
      releaseValue
    }
  }

  return (
    <Grid container spacing={1}>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.containerNo}>
        <CngTextField
          name='containerNo'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.containerNo}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.codAmount}>
        <CngTextField
          name='codAmount'
          inputProps={{ maxLength: 8 }}
          label={translatedTextsObject.codAmount}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.freightCharges}>
        <CngSelectField
          name='freightCharges'
          label={translatedTextsObject.freightCharges}
          disabled={disabled}
          items={[
            { text: 'Prepaid', value: 'P' },
            { text: 'Collect', value: 'C' }
          ]}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={3} shouldHide={shouldHideMap?.declaredValueOfShipment}>
        <CngTextField
          name='declaredValueOfShipment'
          inputProps={{ maxLength: 12 }}
          label={translatedTextsObject.declaredValueOfShipment}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.placeOfLadding}>
        <CngTextField
          name='placeOfLadding'
          inputProps={{ maxLength: 30 }}
          label={translatedTextsObject.placeOfLadding}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.releaseValue}>
        <CngTextField
          name='releaseValue'
          inputProps={{ maxLength: 9 }}
          label={translatedTextsObject.releaseValue}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} md={4} shouldHide={shouldHideMap?.bolCurrency}>
        <CngCodeMasterAutocompleteField
          name='bolCurrency'
          label={translatedTextsObject.bolCurrency}
          disabled={disabled}
          codeType="US_INV_CURR"
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.deliveryInstrctions}>
        <CngTextField
          name='deliveryInstrctions'
          inputProps={{ maxLength: 50 }}
          label={translatedTextsObject.deliveryInstrctions}
          disabled={disabled}
          size='small'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap?.chargeTo}>
        <CngTextField
          name='chargeTo'
          inputProps={{ maxLength: 50 }}
          label={translatedTextsObject.chargeTo}
          disabled={disabled}
          size='small'
          helperText='If other than Shipper or Consignee'
        />
      </CngGridItem>
    </Grid>
  )
}

const BolFormFormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields
})

export default BolFormFormProperties
